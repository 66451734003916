import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [

  //canActivate: [AuthGuard]
  { path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule', canActivate: [AuthGuard]},  
  { path: 'admin', loadChildren: 'app/admin/admin.module#AdminModule', canActivate: [AuthGuard]},
  { path: 'entrepreneur', loadChildren: 'app/entrepreneur/entrepreneur.module#EntrepreneurModule', canActivate: [AuthGuard]},

  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
