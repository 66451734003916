import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { UserInterface, ProjectInterface, MessagesFrirebase } from '../../models/models';
import { keyframes } from '@angular/animations';

@Injectable({
	providedIn: 'root'
})
export class AuthService {


	user: Observable<firebase.User | null>;
	authState: any = null;

	params = {
		'The password is invalid or the user does not have a password.':
			'Contraseña incorrecta. Por favor, revisa e inténtalo de nuevo.',

		'The email address is badly formatted.':
			'Ingresa una dirección de correo electrónico válida.',

		'There is no user record corresponding to this identifier. The user may have been deleted.':
			'El correo electrónico que ingresaste no coincide con nuestros registros.',

		'The email address is already in use by another account.': 
			'La dirección de correo electrónico ya está en uso por otra cuenta.',
			
		'The password must be 6 characters long or more.': 
			'La contraseña debe tener 6 caracteres o más.',    

		'Password should be at least 6 characters': 
			'La contraseña debe tener al menos 6 caracteres'    

	} as MessagesFrirebase; 

	constructor(private firebaseAuth: AngularFireAuth,
				private router: Router,
				private angularFirestore: AngularFirestore,
				private db: AngularFireDatabase
	) {		
		this.user = firebaseAuth.authState;
		this.firebaseAuth.authState.subscribe((auth: any) => {
			//console.log(auth);
			this.authState = auth;
			//console.log("authState: " + this.authState);		
			
		});
	}

	public printErrorByCode(code: string): string {
		//console.log(code);
		var code2 = code.toString().split(": ", 2);

		if (this.params[code2[1]]) {
			return (this.params[code2[1]]);
		} else {
			return ('Error desconocido! \n Codigo de Error: ' + code2[1]);
		}
	}

	async autenticado() {
		
		return firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				return user;
			} else {
				return false;
			}      
		});

	}

	// Devuelve true si el usuario ha iniciado sesión
	get authenticated(): boolean {
		return this.authState !== null;
	}
	// Devuelve los datos actuales del usuario
	get currentUser(): Observable<firebase.User | null> {
		return this.user;
	}
	// Returns
	get currentUserObservable(): any {
		return this.firebaseAuth.authState
	}
	// Devuelve el UID del usuario actual
	get currentUserId(): string {
		return this.authenticated ? this.authState.uid : '';
	}
	// Usuario anónimo
	get currentUserAnonymous(): boolean {
		return this.authenticated ? this.authState.isAnonymous : false
	}
	// Devuelve el nombre para mostrar del usuario actual o invitado
	get currentUserDisplayName(): string {
		if (!this.authState) { 
			return 'Guest' ;
		}
		else if (this.currentUserAnonymous) { 
			return 'Anonymous' ;
		}
		else { 
			return this.authState['displayName'] || 'User without a Name'; 
		}
	}
	//// Social Auth ////

	githubLogin() {
		const provider = new firebase.auth.GithubAuthProvider()
		return this.socialSignIn(provider);
	}

	googleLogin() {
		const provider = new firebase.auth.GoogleAuthProvider()
		return this.socialSignIn(provider);
	}

	facebookLogin() {
		const provider = new firebase.auth.FacebookAuthProvider()
		return this.socialSignIn(provider);
	}

	twitterLogin() {
		const provider = new firebase.auth.TwitterAuthProvider()
		return this.socialSignIn(provider);
	}

	private async socialSignIn(provider: any) {
		return this.firebaseAuth.auth.signInWithPopup(provider)
			.then((credential) => {
				this.authState = credential.user;
				//this.updateUserData();
			})
			.catch(error => console.log(error));
	}
	///////////////////////////////////////////////////////////////////////////////////////////
	//    Email/Password Auth ////
	async emailSignUp(userData) {
		return this.firebaseAuth.auth.createUserWithEmailAndPassword(userData.email, userData.password)
			.then((user) => {
				this.authState = user;        
				
				this.dataInit(userData);
				this.updateUserDataRol(userData);
				this.updateProjects(userData);

				return 1;
			}).catch((error) => {
				var fireError = this.printErrorByCode(error);
				return fireError;
			});
	}

	exportDatabase(){
		let refData = 'usuarios/';
		
		this.db.list(refData).valueChanges().subscribe((data: any) => {
			for (const item of data) {
				const UID = item.uid;
				const key = Date.now().toString();

				let canvas: object = null;

				if (item.proyecto.canvas) {
					let canvas_temp = item.proyecto.canvas;
					canvas = {
						beneficiaries: canvas_temp.beneficiarios,
						costs: canvas_temp.costo,
						impact: canvas_temp.impacto,
						income: canvas_temp.ingreso,
						problem: canvas_temp.problema,
						purpose: canvas_temp.proposito,
						value_proposal: canvas_temp.propuesta,
					}					
				}

				const projectData: ProjectInterface = {
					id: key,
					uid_user: UID,
					name: item.proyecto.name,
					description: item.proyecto.description,
					canvas: canvas
				};

				//this.updateProjects2(projectData).then(() => { console.log(item); });
			}
		});
	}

	private updateUserDataRol(userData) {

		const UID = this.authState.user.uid;

		const userRef: AngularFirestoreDocument<UserInterface> = this.angularFirestore.doc(`users/${UID}`);
		const data: UserInterface = {
			uid: UID,
			name: userData.name,
			surname: userData.surname,
			lastname: userData.lastname,
			email: userData.email,
			phone: userData.phone,
			profession: userData.profession,
			roles: {
				entrepreneur: true
			}
		};
		
		return userRef.set(data, { merge: true });
	}

	private updateUserDataRol2(userData: UserInterface) {
		const userRef: AngularFirestoreDocument<UserInterface> = this.angularFirestore.doc(`users/${userData.uid}`);
		return userRef.set(userData, { merge: true });
	}
	private updateProjects2(projectData: ProjectInterface) {
		const project: AngularFirestoreDocument<ProjectInterface> = this.angularFirestore.doc(`projects/${projectData.id}`);
		return project.set(projectData, { merge: true });
	}


	private updateProjects(userData: any) {
		const UID = this.authState.user.uid;

		const key = Date.now().toString();
		const project: AngularFirestoreDocument<ProjectInterface> = this.angularFirestore.doc(`projects/${key}`);
		const projectData: ProjectInterface = {
			id: key,
			uid_user: UID,
			name: userData.projectName,
			description: userData.projectDescription,
		};
		return project.set(projectData, { merge: true });
	}

	getUser(uid: string){
		let user: Observable<UserInterface[]>
		user = this.angularFirestore.collection('users', ref => ref.where('uid', '==', uid)).valueChanges();
		//console.log(user);
		return user;
	}

	/*
	getUser(uid: string): Observable<UserInterface> {
		let user: Observable<UserInterface>
		user = this.angularFirestore.collection('users', ref => ref.where('uid', '==', uid)).valueChanges()[0];
		return user;
	}
	*/

	isUserAdmin(uid: any){
		return this.angularFirestore.doc(`users/${uid}`).valueChanges();
	}
	///////////////////////////////////////////////////////////////////////////////////////////
	async dataInit(userData: any){

		this.firebaseAuth.auth.signInWithEmailAndPassword(userData.email, userData.password)
			.then((user) => {
				this.authState = user;
				if (this.authState) {

					let refData = 'usuarios/' + this.authState.user.uid;

					let data = {
						user: {
							role: "entrepreneur",
							name: userData.name,
							surname: userData.surname,
							lastname: userData.lastname,
							phone: userData.phone,
							profession: userData.profession,
						},
						proyecto: {
							name: userData.projectName,
							description: userData.projectDescription,
						}
					}
					this.db.database.ref(refData).set(data)
						.then(() => {
							//console.log("Datos ok");
							this.signOut();
						});
				}
				//console.log(this.authState);         
			})
			.catch((error) => {
				var fireError = this.printErrorByCode(error);
				//console.log(fireError)
				return fireError;
			});
	}

	async emailLogin(email: string, password: string) {

		return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password)
			.then((user) => {
				this.authState = user;
				//console.log("Paso por  login:");

				//Crear sessionStorage
				sessionStorage.setItem('uid', user.user.uid);
				sessionStorage.setItem('email', email);
				sessionStorage.setItem('password', password);
				sessionStorage.setItem('userLogin', 'true');

				//console.log(this.authState.user);

				this.getUser(user.user.uid).subscribe((data)=>{
					//console.log(data);
					//console.log("Paso por getUser:");
					const isAdmin = Object.assign({}, data[0].roles).hasOwnProperty('admin');
					//console.log(isAdmin);
					if (isAdmin) {
						this.router.navigateByUrl('/admin');
					} else {
						this.router.navigateByUrl('/entrepreneur');
					}
				});								
			})
			.catch((error) => {
				var fireError = this.printErrorByCode(error);
				//console.log(fireError)
				return fireError;
			});
	}

	async persistenceSession() {
		var email = sessionStorage.getItem('email');
		var password = sessionStorage.getItem('password');

		if (email == null || password == null) {
			email = "example@mail.com";
			password = "123456"
		}

		this.firebaseAuth.auth.signInWithEmailAndPassword(email, password)
			.then((user) => {
				//console.log("Persiste ", this.authenticated);        
			})
			.catch((error) => {
				sessionStorage.removeItem('uid');
				sessionStorage.removeItem('email');
				sessionStorage.removeItem('password');
				sessionStorage.removeItem('userLogin');

				//console.log("No Persiste ", this.authenticated );        
				this.router.navigateByUrl('/login');
			});
	}

	// Envía un correo electrónico permitiendo al usuario restablecer la contraseña
	async resetPassword(email: string) {
		var auth = firebase.auth();

		return auth.sendPasswordResetEmail(email)
			.then(() => console.log("email sent"))
			.catch((error) => console.log(error))
	}
	//// Desconectar ////
	signOut(): void {
		this.firebaseAuth.auth.signOut().then(
			() => {
				sessionStorage.removeItem('uid');
				sessionStorage.removeItem('email');
				sessionStorage.removeItem('password');
				sessionStorage.removeItem('userLogin');
				this.router.navigateByUrl('/');
			}
		);
		//this.router.navigate(['/']);
	}
	//// Ayudantes ////
	private updateUserData(): void {
		// Escribe el nombre de usuario y el correo electrónico en tiempo real db
		// útil si su aplicación muestra información sobre usuarios o para funciones de administración

		let path = `users/${this.currentUserId}`; // Punto final en base de fuego
		let data = {
			email: this.authState.email,
			name: this.authState.displayName
		}

		this.db.object(path).update(data)
			.catch(error => console.log(error));

	}

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	signup(email: string, password: string) {
		this.firebaseAuth
			.auth
			.createUserWithEmailAndPassword(email, password)
			.then(value => {
				console.log('Success!', value);
			})
			.catch(err => {
				console.log('Something went wrong:', err.message);
			});
	}

	login(email: string, password: string) {
		this.firebaseAuth
			.auth
			.signInWithEmailAndPassword(email, password)
			.then(value => {
				this.router.navigateByUrl('/user');
				//console.log('Nice, it worked!');
			})
			.catch(err => {
				//console.log('Something went wrong:', err.message);
			});
	}

	logout() {
		this.firebaseAuth.auth.signOut()
		.then(
			() => {
				this.router.navigateByUrl('/');
			}
		);
	}
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}
