import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

export class AuthGuard implements CanActivate {

	constructor(private authServices: AuthService,
							private asfAuth: AngularFireAuth,
							private router: Router,
	){}
	

	canActivate(): boolean {
		/*return new Promise((resolve, reject) => {
			this.authServices.currentUser.subscribe((user)=>{
				this.router.navigate(['/admin']);
				return resolve(false);
			});
		})*/
		return true;
	}
}
