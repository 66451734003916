import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { AnalyticsService } from '../../../@core/utils';
import { LayoutService } from '../../../@core/utils';
import { AngularFireDatabase } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;

  userMenu = [
    { 
      title: 'Perfil',
      link: 'entrepreneur/profile'
    }, 
    { 
      title: 'Cerrar sesión',
      link: 'auth/logout'
    }
  ];

  userObj: Object = null;
  uid: String;
  imgProfile: any;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private analyticsService: AnalyticsService,
              private storage: AngularFireStorage,
              private layoutService: LayoutService,
              private afAuth: AngularFireAuth,
              private authService: AuthService,
              private db: AngularFireDatabase) {
    
  }

  ngOnInit() {
    this.getDataUser();
  }

  getDataUser() {

    //console.log(this.authService.currentUser);
  
    this.afAuth.authState.subscribe( (user) => {
        if (user) {
          const uid = user.uid;
          this.getPhotoURL(uid);          
          
          this.authService.getUser(uid).subscribe((data) => {
            this.userObj = {
              username: data[0].name + ' ' + data[0].surname,
              email: data[0].email
            }
          });
        }
      }, (err) => { console.log(err); }
    );
    
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  getPhotoURL(uid: string) {
    const filePath = "uploads/users/" + uid + "/profile.png";
    const ref = this.storage.ref(filePath);

    ref.getDownloadURL().subscribe(
      url => {
        //console.log(url);
        this.imgProfile = url;
      },
      err => {
        //console.log("Error: " + err);
        this.imgProfile = "assets/img/user.png";
      }
    );
  }
}
